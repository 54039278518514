import {applyMiddleware, createStore, compose, combineReducers} from 'redux';
import * as reducers from './ducks';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();

history.listen(location => {
  window.gtag('config', 'UA-66314202-2', {
    'page_location': window.location.href,
    'page_path': location.pathname + location.search,
  });
});

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  /** @namespace window.__REDUX_DEVTOOLS_EXTENSION__ */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const rootReducer = combineReducers(reducers);

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers,
);

export default store;
